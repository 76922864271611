/**
 *
 * Meetings
 *
 */
import React, { useEffect, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useCommitteesSlice } from '../Committees/slice/hook';
import { Item } from './Item';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { SectionHeader } from 'app/components/SectionHeader';
import { Box, Button, Container } from '@mui/material';
import { SectionMenu } from 'app/components/SectionMenu';
import { UpcomingAndRecent } from './UpcomingAndRecent';
import { Past } from './Past';
import { RiAddCircleFill } from 'react-icons/ri';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { selectCommittees } from '../Committees/slice/selectors';
import { StartBoard } from './StartBoard';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useEffectOnce } from 'usehooks-ts';
import { DashedButton } from 'app/components/DashedButton';
import {
  selectAuthProvider,
  selectUserData,
} from '../../providers/AuthProvider/slice/selectors';
import { useLoadCommittees } from 'utils/useLoadCommittees';

interface Props {
  organisation: IOrganisation;
}

export function Meetings(props: Props) {
  const { organisation } = props;
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const { loadUser } = useSelector(selectAuthProvider);
  const { loadMeetings, loadMeeting } = useSelector(selectMeetings);

  const { open } = useMeetingFormWidget();

  const { hasPermission } = useHasPermission();

  const canManageCommittees = hasPermission(
    'manage-committees',
    'organisation',
    organisation.id,
  );
  const location = useLocation();

  const canCreateMeetings = useCanCreateMeetings(organisation);
  const { reloadCommittees, loadCommittees, hasCommittees } = useLoadCommittees(
    organisation.id,
  );

  useEffectOnce(() => {
    reloadCommittees();
  });

  const calendar_url = useMemo(() => {
    return loadUser.data.access_organisations.find(
      org => org.id === organisation.id,
    )?.calendar_url;
  }, [loadUser.data?.access_organisations, organisation.id]);

  useEffect(() => {
    // console.log(calendar_url);
  }, [calendar_url]);

  useEffectOnChange(() => {
    reloadOrganisation();
  }, [loadMeetings.data.length, hasCommittees]);

  const navigate = useNavigate();

  const { close, isOpen } = useMeetingFormWidget();

  if (!organisation) return null;

  // Fix for dodgy logic on routes which makes meeting links not work when refreshed
  if (!loadCommittees.touched || (loadCommittees.loading && !hasCommittees))
    return <LoadingIndicator />;

  return (
    <>
      <Helmet title={'Meetings'}>
        <meta name="description" content="Meetings of the organisation" />
      </Helmet>

      <SectionHeader
        title={'My meetings'}
        sectionMenu={
          hasCommittees ? (
            <>
              <SectionMenu
                title="Upcoming & recent"
                path="./upcoming"
                isActive={
                  location.pathname ===
                  `/organisation/${organisation.id}/meetings/upcoming`
                }
              />
              <SectionMenu
                title="Past meetings"
                path="./past"
                isActive={
                  location.pathname ===
                  `/organisation/${organisation.id}/meetings/past`
                }
              />
            </>
          ) : undefined
        }
        cta={
          canCreateMeetings && (
            <>
              <Button
                color="secondary"
                startIcon={<RiAddCircleFill />}
                onClick={() => {
                  open({ organisation_id: organisation.id });
                }}
              >
                Schedule new meeting
              </Button>
              {canCreateMeetings && (
                <MeetingFormDialog
                  open={isOpen}
                  onClose={close}
                  onChange={meeting =>
                    navigate(
                      `/organisation/${organisation.id}/meetings/${meeting.id}`,
                    )
                  }
                />
              )}
            </>
          )
        }
      />

      <Container>
        <Box my={4}>
          <Routes>
            {!hasCommittees ? (
              <>
                {canManageCommittees ? (
                  <>
                    {!loadCommittees.loading && (
                      <>
                        <Route
                          path={''}
                          element={<StartBoard organisation={organisation} />}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Route
                    path={''}
                    element={
                      <DashedButton
                        title="No Meetings"
                        description="If you believe this is an error, contact your Board adminstrator"
                      />
                    }
                  />
                )}
                <Route path={'*'} element={<Navigate to={''} />} />
              </>
            ) : (
              <>
                <Route
                  path={':id/*'}
                  element={<Item organisation={organisation} />}
                />
                <Route
                  path={'upcoming'}
                  element={<UpcomingAndRecent organisation={organisation} />}
                />
                <Route
                  path={'past'}
                  element={<Past organisation={organisation} />}
                />
                <Route path={'*'} element={<Navigate to={'upcoming'} />} />
              </>
            )}
          </Routes>
        </Box>
      </Container>
    </>
  );
}
