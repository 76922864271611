import {
  Button,
  ButtonProps,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { RiAlertLine, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { noop } from 'utils/noop';

interface Props extends Omit<ButtonProps, 'startIcon' | 'endIcon'> {
  title: string;
  onClose?: () => void;
  path?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  handleClick?: () => void;
  isEnabled: boolean;
}

export function Nag(props: Props) {
  const {
    title,
    startIcon = <RiAlertLine size={15} title="Alert" />,
    path,
    isEnabled,
    onClose,
    endIcon,
    handleClick = noop,
    sx,
    ...rProps
  } = props;

  return (
    <>
      {isEnabled && (
        <Button
          to={path}
          component={Link}
          fullWidth
          onClick={handleClick}
          {...rProps}
          sx={{
            borderRadius: 0,
            p: 1.5,
            ...sx,
          }}
        >
          <Container>
            <Stack direction={'row'}>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                flexGrow={1}
              >
                {startIcon}
                <Typography variant="body2">{title}</Typography>
                {endIcon}
              </Stack>
              {!!onClose && (
                <IconButton
                  aria-label="Close"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClose();
                  }}
                  size="small"
                >
                  <RiCloseLine />
                </IconButton>
              )}
            </Stack>
          </Container>
        </Button>
      )}
    </>
  );
}
