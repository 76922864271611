import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';

interface Props extends ButtonBaseProps {
  label: string;
  open: boolean;
  enabled: boolean;
}

export function DrawerButton(props: Props) {
  const { label, enabled, open, sx, ...rProps } = props;
  const bgBlack = {
    backgroundColor: 'grey.800',
    '&:hover': {
      backgroundColor: 'grey.900',
      opacity: 0.8,
    },
  };

  const topBarHeight = 6;
  const arrowSize = 25;

  return (
    <>
      {enabled && (
        <ButtonBase
          sx={{
            ...bgBlack,
            px: 2,
            mb: 1,
            height: topBarHeight,
          }}
          {...rProps}
        >
          <Box
            sx={{
              position: 'absolute',
              top: topBarHeight,
              left: '50%',
              px: 3,
              pb: 1,
              borderBottomRightRadius: 12,
              borderBottomLeftRadius: 12,
              transform: 'translateX(-50%)',
              color: 'common.white',
              backgroundColor: 'inherit',
            }}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant={'body2'}>{label}</Typography>
              {open ? (
                <RiArrowDropUpLine size={arrowSize} />
              ) : (
                <RiArrowDropDownLine size={arrowSize} />
              )}
            </Stack>
          </Box>
        </ButtonBase>
      )}
    </>
  );
}
