/**
 *
 * ActionRegisterFormWidget
 *
 */
import React, { useEffect, useRef, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { IActionRegisterItem } from 'types/types';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { ActionDetails } from './steps/ActionDetails';
import { CheckAndFinish } from './steps/CheckAndFinish';
import key from 'weak-key';
import { AssignOwner } from './steps/AssignOwner';
import { RiCloseLine } from 'react-icons/ri';
import { ActionRegisterCommittee } from '../../slice/types';
import moment from 'moment-timezone';
import { WizardContainer } from 'app/components/WizardContainer';

interface Props {
  action?: IActionRegisterItem | undefined;
  onSave: (payload: ActionRegisterFormType) => void;
  onClose?: () => void;
  onDelete?: () => void;
  saving: boolean;
  committeePath: string;
  committees: ActionRegisterCommittee[];
  committee_id?: number | string;
  meeting_id?: number | string;
  organisation_id: number | string;
}

const schemaArr = [
  {
    description: Yup.string().required('This field is required'),
    committee_id: Yup.number().required('This field is required'),
    due_at: Yup.date()
      .typeError('Due date is required')
      .required('Due date is required'),
  },
  {},
  {},
];

export type ActionRegisterFormType = Partial<IActionRegisterItem> & {
  member_ids?: (string | number)[];
};

export function ActionRegisterForm(props: Props) {
  const {
    onSave,
    onClose,
    saving,
    committeePath,
    organisation_id,
    committees,
    committee_id,
    meeting_id,
  } = props;

  const [step, setStep] = useState<number>(0);

  const steps = [
    {
      label: 'Action details',
      component: (
        <ActionDetails
          onClose={onClose}
          committees={committees}
          next={() => setStep(1)}
        />
      ),
    },
    {
      label: 'Assign an owner',
      component: (
        <AssignOwner
          committees={committees}
          committeePath={committeePath}
          previous={() => setStep(0)}
          next={() => setStep(2)}
        />
      ),
    },
    {
      label: 'Check and finish',
      component: (
        <CheckAndFinish
          onClose={onClose}
          committees={committees}
          previous={() => setStep(1)}
          saving={saving}
        />
      ),
    },
  ];

  const formikRef = useRef(null);
  useEffect(() => {
    formikRef.current?.validateForm();
  }, [step]);

  const indexTab = steps.findIndex((tab, index) => index === step);
  const currentValidationSchema = Yup.object().shape(
    Object.assign({}, ...schemaArr.slice(0, indexTab + 1)),
  );

  return (
    <Formik<ActionRegisterFormType>
      innerRef={formikRef}
      enableReinitialize
      validateOnMount
      validationSchema={currentValidationSchema}
      initialValues={{
        id: undefined,
        description: '',
        organisation_id,
        status: 'open',
        committee_id: committee_id || null,
        meeting_id: meeting_id || null,
        owner_names: [],
        due_at: '',
        member_ids: [],
      }}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          due_at: moment.utc(values.due_at).format('YYYY-MM-DD HH:mm:ss'),
        });
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting } = formik;
        if (isSubmitting && !saving) setSubmitting(false);
        return (
          <FormikForm>
            <WizardContainer sx={{ py: 0, px: 0 }}>
              <DialogTitle>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography variant="h2">Add an action</Typography>
                  <IconButton edge={'end'} size={'large'} onClick={onClose}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Stack spacing={4}>
                  <Stepper activeStep={indexTab}>
                    {steps.map((s, i) => (
                      <Step key={key(s)} active={step === i}>
                        <StepLabel>{s.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {steps[step].component}
                </Stack>
              </DialogContent>
            </WizardContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
