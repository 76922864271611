import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useMemo } from 'react';
import { getInitials } from 'utils/initials';
import { ActionRegisterCommittee } from 'app/widgets/ActionRegister/slice/types';
import { ActionRegisterFormType } from '../../ActionRegisterDetailsForm';
import { capitalize } from 'lodash';
import { TitledField } from 'app/components/TitledField';
import key from 'weak-key';

interface Props {
  onClose: () => void;
  previous: () => void;
  committees: ActionRegisterCommittee[];
  saving: boolean;
}

export function CheckAndFinish(props: Props) {
  const { onClose, previous, committees, saving } = props;
  const { values } = useFormikContext<ActionRegisterFormType>();

  const committee = useMemo(() => {
    return committees.find(c => c.id === values.committee_id);
  }, [committees, values.committee_id]);

  const members = useMemo(() => {
    return committee?.members || [];
  }, [committees, values.committee_id]);

  const selectedMembers = useMemo(() => {
    return members.filter(m => values.member_ids.includes(m.member_id));
  }, [members, values.member_ids]);

  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Typography variant="h2">Check and finish</Typography>
      <Typography variant="body2">
        Please review all the information entered in the form before submitting.
        Ensure that all fields are correct and complete. If you need to make any
        changes, you can go back to previous steps to update the details.
      </Typography>

      <TitledField title="Action">
        <Typography variant="body2">{values.description}</Typography>
      </TitledField>
      <Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <TitledField title="Status">
              <Typography variant="body2">
                {capitalize(values.status)}
              </Typography>
            </TitledField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TitledField title="Committee">
              <Typography variant="body2">{committee.name}</Typography>
            </TitledField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TitledField title="Due">
              <Typography variant="body2">
                {moment(values.due_at).format('DD/MM/YYYY')}
              </Typography>
            </TitledField>
          </Grid>
        </Grid>
      </Box>

      <TitledField title="Assigned to">
        {!!selectedMembers.length ? (
          <Stack
            spacing={0}
            direction={'row'}
            alignItems={'center'}
            flexWrap={'wrap'}
          >
            {selectedMembers.map(m => (
              <Stack
                key={key(m)}
                spacing={1}
                direction={'row'}
                alignItems={'center'}
                mr={3}
                mb={2}
              >
                <Avatar
                  sx={{
                    backgroundColor: 'common.beige',
                    color: 'common.black',
                  }}
                >
                  {getInitials(`${m.first_name} ${m.last_name}`)}
                </Avatar>
                <Typography variant="body2">{`${m.first_name} ${m.last_name}`}</Typography>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant="body2">No members assigned</Typography>
        )}
      </TitledField>

      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={previous}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth type="submit" disabled={saving || isSubmitting}>
              Publish this action
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
