/**
 *
 * Portal
 *
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocalStorage } from 'usehooks-ts';
import { AppNavBar } from 'app/components/AppNavBar';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { OrganisationPage } from '../OrganisationPage';
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ProfilePage } from '../ProfilePage';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentOrganisations } from 'app/providers/OrganisationSelector/slice/selectors';
import { HomePage } from '../HomePage';
import { useEffect, useMemo, useState } from 'react';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { useDebounce } from 'utils/useDebounce';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { noop } from 'utils/noop';
import { useConfirm } from 'utils/useConfirm';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { Nag } from 'app/components/Nag';
import { now } from 'moment';
import TagManager from 'react-gtm-module';
import { NavigationProvider } from 'app/providers/NavigationProvider';
import { useGetOrganisationMenuStructure } from '../OrganisationPage/useGetOrganisationMenuStructure';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import { useRoutedTabs } from 'app/providers/AuthProvider/useRoutedTabs';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { SettingsProvider } from 'app/providers/SettingsProvider';
import { RiArrowDropUpLine } from 'react-icons/ri';
import { DrawerButton } from 'app/components/DrawerButton';
import key from 'weak-key';

export const Portal = React.memo(() => {
  const orgs = useSelector(selectCurrentOrganisations);
  const location = useLocation();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { loadUser, sendEmailVerification } = useSelector(selectAuthProvider);

  const { tabs } = useGetOrganisationMenuStructure();
  const { currentOrganisationId } = useOrganisationSelector();

  const { RoutedTabs } = useRoutedTabs(
    tabs,
    `/organisation/${currentOrganisationId}`,
  );

  const navigate = useNavigate();

  // Disable nag for now.
  const [nagDismissed, setNagDismissed] = useLocalStorage<number>(
    `nagDismissed-${loadUser.data?.id}`,
    0,
  );
  const debouncedLocation = useDebounce(location, 3000);

  useHandleApiResponse(sendEmailVerification, 'Email verification sent', {
    onSuccess: () => {
      dispatch(authProviderActions.loadUserDataRequest());
    },
  });

  useEffect(() => {
    const userId = loadUser.data ? loadUser.data.id : null;
    const tagManagerArgs = {
      dataLayer: {
        event: 'userData',
        userId,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [loadUser.data]);

  useEffect(() => {
    dispatch(authProviderActions.loadUserDataRequest());
  }, [debouncedLocation]);

  // Set nag date to 7 days from now
  const onClose = () => setNagDismissed(now() + 7 * 24 * 60 * 60 * 1000);
  const securityPath = location.pathname === 'profile/security';

  const showMfaNag = useMemo<boolean>(() => {
    if (!loadUser.data) return false;

    if (!loadUser.data.show_mfa_nag) return false;

    if (nagDismissed > now() && !loadUser.data.enforce_mfa) {
      return false;
    }

    return !loadUser.data.mfa_method;
  }, [nagDismissed, securityPath, loadUser.data]);

  const hasVerifiedEmail =
    loadUser.data && loadUser.data?.email_verification_status !== 'verified';

  const [showAllNags, setShowAllNags] = useState<boolean>(false);

  const nags = [
    {
      isEnabled: showMfaNag,
      title: 'Set up 2-factor authentication to secure your account now',
      path: '/profile/security',
      color: 'secondary' as const,
      onClose: loadUser.data?.enforce_mfa ? undefined : onClose,
    },
    {
      isEnabled: hasVerifiedEmail,
      title:
        loadUser.data?.email_verification_status === 'pending'
          ? 'Check your email to verify your account'
          : 'Verify your email to secure your account now',
      color: 'warning' as const,
      handleClick: () => {
        if (loadUser.data?.email_verification_status === 'pending') {
          confirm({
            title: 'Email verification',
            description:
              'We have sent an email to your address with a verification link. Please check your email and click the link to verify your email address. Be sure to check your spam folder(s) if this has not arrived within a few minutes.',
            hideCancelButton: true,
            confirmationText: 'Ok',
            dialogActionsProps: { sx: { justifyContent: 'end' } },
          })
            .then(noop)
            .catch(noop);
        } else {
          dispatch(authProviderActions.sendEmailVerificationRequest());
        }
      },
    },
  ];

  const enabledNags = nags.filter(nag => nag.isEnabled);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NavigationProvider>
        <SettingsProvider>
          <>
            <Helmet>
              <title>Governance360</title>
              <meta name="description" content="Governance360 application." />
            </Helmet>
            <>
              <Box sx={{ position: 'sticky', top: 0, zIndex: 400 }}>
                <Stack spacing={0}>
                  <AppNavBar
                    currentPath={location.pathname}
                    menuComponent={<RoutedTabs />}
                    logout={() => navigate(`/logout?redirect=/`)}
                  />

                  {(showAllNags || enabledNags.length === 1) && (
                    <>
                      {enabledNags.map(nag => (
                        <Nag key={key(nag)} {...nag} />
                      ))}
                    </>
                  )}
                  <DrawerButton
                    enabled={enabledNags.length > 1}
                    label={
                      showAllNags
                        ? 'Roll up notices'
                        : 'Click here to view important notices'
                    }
                    open={showAllNags}
                    onClick={() => setShowAllNags(!showAllNags)}
                  />
                </Stack>
              </Box>
              <Stack
                id="main-content"
                spacing={0}
                sx={{
                  minHeight: theme =>
                    `calc(100vh - ${+theme.mixins.toolbar.minHeight + 6}px)`,
                }}
                justifyContent={'space-between'}
              >
                <Box>
                  <Routes>
                    <Route
                      path={'no-organisations'}
                      element={
                        <Container>
                          <Box sx={{ py: 4 }}>
                            <Stack spacing={4}>
                              <Typography variant={'h2'}>
                                No linked organisations
                              </Typography>
                              <Typography variant={'body1'}>
                                Please contact Gov360 or signup on one of our
                                plans.{' '}
                              </Typography>
                            </Stack>
                          </Box>
                        </Container>
                      }
                    />
                    <Route
                      path={'organisation/*'}
                      element={
                        <Routes>
                          <Route
                            path={':id/*'}
                            element={<OrganisationPage />}
                          />
                          <Route
                            path={'*'}
                            element={
                              <Navigate
                                to={`${orgs.length ? orgs[0] : '/'}`}
                                replace
                              />
                            }
                          />
                        </Routes>
                      }
                    />
                    <Route path={'profile/*'} element={<ProfilePage />} />
                    <Route path={'*'} element={<HomePage />} />
                  </Routes>
                </Box>
                <Box
                  sx={{
                    py: 2,
                    mt: 4,
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                  }}
                >
                  <Container>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography>
                        &copy; {new Date().getFullYear()} Governance360
                      </Typography>

                      <Link
                        href="https://knowledge.governance360.com/"
                        target="_blank"
                        rel="noopener"
                        sx={{
                          color: 'inherit',
                        }}
                      >
                        <Typography>Knowledge base</Typography>
                      </Link>
                    </Stack>
                  </Container>
                </Box>
              </Stack>
            </>
          </>
        </SettingsProvider>
      </NavigationProvider>
    </LocalizationProvider>
  );
});
